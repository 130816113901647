import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "unloading-loading-area" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_4 = {
    key: 1,
    class: "column-description"
};
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_7 = {
    key: 1,
    class: "column-description"
};
const _hoisted_8 = { class: "column-header" };
const _hoisted_9 = { class: "column-description" };
const _hoisted_10 = { class: "column-header" };
const _hoisted_11 = { class: "column-description" };
const _hoisted_12 = { class: "column-header" };
const _hoisted_13 = { class: "column-description" };
const _hoisted_14 = { class: "column-header" };
const _hoisted_15 = { class: "column-description" };
const _hoisted_16 = { class: "column-header" };
const _hoisted_17 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_18 = {
    key: 1,
    class: "column-description"
};
const _hoisted_19 = { class: "column-header" };
const _hoisted_20 = { class: "column-description" };
const _hoisted_21 = { class: "column-header" };
const _hoisted_22 = { class: "column-description" };
const _hoisted_23 = { class: "column-header" };
const _hoisted_24 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_25 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.submoduleTitle,
            icon: "local_shipping",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('loady2go.no-data')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.unloadingLoadingArea?.cargoTypes?.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.basic-information.un-loading-point.filling-types")), 1),
                        (_ctx.unloadingLoadingArea?.cargoTypes?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.unloadingLoadingArea.cargoTypes, 'FillingTypes'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.unloadingLoadingArea?.transportModes?.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("sub-modules.basic-information.un-loading-point.transport-modes")), 1),
                        (_ctx.unloadingLoadingArea?.transportModes?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.unloadingLoadingArea.transportModes, 'TransportModes'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.unloadingLoadingArea.location
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("sub-modules.unloading-loading-area.location")), 1),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getValue(_ctx.unloadingLoadingArea.location, "Location")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.unloadingLoadingArea.railConnection !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.unloading-loading-area.rail-connection")), 1),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.railConnection), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.unloadingLoadingArea.exZone
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("sub-modules.unloading-loading-area.ex-zone")), 1),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.getValue(_ctx.unloadingLoadingArea.exZone, "ExZone")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.unloadingLoadingArea.keyLocation
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("sub-modules.unloading-loading-area.key-location")), 1),
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.getValue(_ctx.unloadingLoadingArea.keyLocation, "KeyLocation")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.unloadingLoadingArea.areaProvisions.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("sub-modules.unloading-loading-area.area-provisions")), 1),
                        (_ctx.unloadingLoadingArea.areaProvisions.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.unloadingLoadingArea.areaProvisions, 'AreaProvisions'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_18, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.unloadingLoadingArea.surfaceType
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("sub-modules.unloading-loading-area.surface-type")), 1),
                        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.getValue(_ctx.unloadingLoadingArea.surfaceType, "SurfaceType")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.unloadingLoadingArea.vehiclePosition
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("sub-modules.unloading-loading-area.vehicle-position")), 1),
                        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.getValue(_ctx.unloadingLoadingArea.vehiclePosition, "VehiclePosition")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.unloadingLoadingArea.protectionAgainstVehicleMovement.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("sub-modules.unloading-loading-area.vehicle-movement-protection")), 1),
                        (_ctx.unloadingLoadingArea.protectionAgainstVehicleMovement.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.unloadingLoadingArea.protectionAgainstVehicleMovement, 'ProtectionAgainstVehicleMovement'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_25, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
