import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7e199189"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "attachments-title" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "column-description" };
const _hoisted_4 = {
    key: 0,
    class: "column-description description"
};
const _hoisted_5 = ["href"];
const _hoisted_6 = {
    key: 1,
    class: "column-description mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
        (_ctx.attachments?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (attachment, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: idx,
                        class: "attachment-element"
                    }, [
                        _createElementVNode("div", _hoisted_3, _toDisplayString(attachment.title), 1),
                        (attachment.description)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(attachment.description), 1))
                            : _createCommentVNode("", true),
                        (attachment.url)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: attachment.url,
                                target: "_blank",
                                class: "url"
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t("shared.components.attachment-widget.more-information")) + " ", 1),
                                _createVNode(_component_Icon, { name: "launch" })
                            ], 8, _hoisted_5))
                            : _createCommentVNode("", true)
                    ]));
                }), 128))
            ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.emptyStateMsg), 1))
    ], 64));
}
