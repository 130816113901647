import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_Button = _resolveComponent("Button");
    const _component_Dialog = _resolveComponent("Dialog");
    return (_openBlock(), _createBlock(_component_Dialog, {
        visible: _ctx.isPopUpVisible,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event) => ((_ctx.isPopUpVisible) = $event)),
        showHeader: false,
        modal: true,
        closeOnEscape: false
    }, {
        default: _withCtx(() => [
            _createVNode(_component_Icon, {
                class: "close",
                name: "close",
                onClick: _ctx.closeModal
            }, null, 8, ["onClick"]),
            _createVNode(_component_Icon, {
                class: "info",
                variant: "filled",
                name: _ctx.iconName
            }, null, 8, ["name"]),
            _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.description), 1),
            _createVNode(_component_Button, {
                class: "p-button-lg",
                onClick: _ctx.deleteOrder
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.button), 1)
                ]),
                _: 1
            }, 8, ["onClick"])
        ]),
        _: 1
    }, 8, ["visible"]));
}
