import { createStore } from "vuex";
import { UiModule } from "@/store/modules/ui.module";
import { SummaryModule } from "@/store/modules/summary.module";
import { CoreModule } from "@/store/modules/core.module";
export const store = createStore({
    modules: {
        core: CoreModule,
        ui: UiModule,
        summary: SummaryModule,
    },
});
