import { openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-33b4db31"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = {
    key: 1,
    class: "thumbnail-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_ctx.src)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "thumbnail-img",
            src: _ctx.src
        }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Icon, { name: _ctx.icon }, null, 8, ["name"])
        ]));
}
