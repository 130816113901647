import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cb656328"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "content",
    ref: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["collapsible-submodule-item", { 'collapsed-submodule-item': _ctx.collapsedItem }])
    }, [
        _createElementVNode("div", {
            class: "title",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
        }, [
            _createVNode(_component_Icon, {
                variant: "outlined",
                name: "expand_more"
            }),
            _createElementVNode("p", null, _toDisplayString(_ctx.$props.title), 1)
        ]),
        _createElementVNode("div", {
            class: "content-container",
            style: _normalizeStyle({ maxHeight: _ctx.collapsedItem ? `${_ctx.contentHeight}px` : 0 })
        }, [
            _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 512)
        ], 4)
    ], 2));
}
