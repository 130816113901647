import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b0db045"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "column-title" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = {
    key: 1,
    class: "column-description"
};
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = {
    key: 1,
    class: "column-description"
};
const _hoisted_8 = { class: "column-header" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_AccessRestriction = _resolveComponent("AccessRestriction");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("sub-modules.parking-space.parking-specific-title")), 1),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.data.parkingNotAllowedFor.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.parking-space.parking-not-allowed-label")), 1),
                (_ctx.data.parkingNotAllowedFor.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.parkingNotAllowedFor, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: item + index,
                                class: "column-description description-item"
                            }, _toDisplayString(_ctx.getValue(item, "ParkingNotAllowedFor")), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.data.productGroupsNotAllowedOnParkingSpace.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("sub-modules.parking-space.product-not-allowed-label")), 1),
                (_ctx.data.productGroupsNotAllowedOnParkingSpace.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.productGroupsNotAllowedOnParkingSpace, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: item + index,
                                class: "column-description description-item"
                            }, _toDisplayString(_ctx.getValue(item, "FillingTypes")), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, "-"))
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("sub-modules.parking-space.size-specific-restriction")), 1),
        _createVNode(_component_AccessRestriction, { accessRestriction: _ctx.data }, null, 8, ["accessRestriction"])
    ], 64));
}
