import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "empty-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SitePpeRequirements = _resolveComponent("SitePpeRequirements");
    return (_ctx.allDisabled())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.ppeHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_SitePpeRequirements, {
                    key: 0,
                    ppeRequirement: _ctx.data.vehicleAndPersonalProtectiveEquipment,
                    hasData: _ctx.ppeHasData()
                }, null, 8, ["ppeRequirement", "hasData"]))
                : _createCommentVNode("", true)
        ], 64));
}
