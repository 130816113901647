import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-073c6977"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "empty-description"
};
const _hoisted_2 = {
    key: 0,
    class: "empty-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AccessRestriction = _resolveComponent("AccessRestriction");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_ctx.allDisabled())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.siteAccessRestrictionHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_SubModule, {
                    key: 0,
                    title: _ctx.$t('loady2go.sub-modules.access-restrictions.site-access-restrictions'),
                    icon: "local_shipping"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_AccessRestriction, {
                            accessRestriction: _ctx.data.siteAccessRestriction
                        }, null, 8, ["accessRestriction"])
                    ]),
                    _: 1
                }, 8, ["title"]))
                : _createCommentVNode("", true),
            (_ctx.ulpAccessRestrictionHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_SubModule, {
                    key: 1,
                    title: _ctx.ulpAccessRestrictionTitle,
                    icon: "local_shipping"
                }, {
                    default: _withCtx(() => [
                        (!_ctx.isUlpAccessRestrictionAvailable)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
                            : (_openBlock(), _createBlock(_component_AccessRestriction, {
                                key: 1,
                                accessRestriction: _ctx.data.unloadingLoadingPointAccessRestriction
                            }, null, 8, ["accessRestriction"]))
                    ]),
                    _: 1
                }, 8, ["title"]))
                : _createCommentVNode("", true)
        ], 64));
}
