import { NavigationSubmodule } from "@/app/types/store.type";
export const NavigationSubmoduleItems = [
    {
        translateKey: "sub-modules.gates.address",
        iconName: "place",
        navigationSubmodule: NavigationSubmodule.Address,
    },
    {
        translateKey: "shared.components.opening-hours-widget.title",
        iconName: "watch_later",
        navigationSubmodule: NavigationSubmodule.OpeningHours,
    },
    {
        translateKey: "loady2go.sub-modules.access-restrictions.name",
        iconName: "local_shipping",
        navigationSubmodule: NavigationSubmodule.AccessRestrictions,
    },
    {
        translateKey: "loady2go.sub-modules.parking.name",
        iconName: "local_parking",
        navigationSubmodule: NavigationSubmodule.Parking,
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.components.edit-infrastructures-nearby.infrastructure-titles",
        isSvg: true,
        iconName: "storefront",
        navigationSubmodule: NavigationSubmodule.Infrastructure,
    },
    {
        translateKey: "loady2go.sub-modules.weighing-bridge.name",
        isSvg: true,
        iconName: "weighingBridges",
        navigationSubmodule: NavigationSubmodule.WeighingBridge,
    },
    {
        translateKey: "loady2go.sub-modules.check-in.name",
        iconName: "meeting_room",
        navigationSubmodule: NavigationSubmodule.CheckIn,
    },
    {
        translateKey: "loady2go.sub-modules.ppe-requirements.name",
        navigationSubmodule: NavigationSubmodule.PPERequirements,
        iconName: "masks",
    },
    {
        translateKey: "domains.lanes.lane-form.loading-point.label",
        isSvg: true,
        iconName: "loadingPoint",
        navigationSubmodule: NavigationSubmodule.LoadingPoint,
    },
    {
        translateKey: "domains.lanes.lane-form.unloading-point.label",
        isSvg: true,
        iconName: "unloadingPoint",
        navigationSubmodule: NavigationSubmodule.UnloadingPoint,
    },
    {
        translateKey: "modules.instructions.display",
        iconName: "edit_note",
        navigationSubmodule: NavigationSubmodule.Instructions,
    },
    {
        translateKey: "modules.technical-requirements.display",
        iconName: "handyman",
        navigationSubmodule: NavigationSubmodule.TechnicalRequirements,
    },
];
