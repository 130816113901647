import { createRouter, createWebHashHistory } from "vue-router";
import FallbackViewComponent from "@/app/views/fallback-view.component.vue";
import UlpInformationComponent from "@/app/views/ulp-information.component.vue";
import { store } from "@/store/app.store";
import OrderListComponent from "@/app/views/order-list/order-list.component.vue";
import moment from "moment";
import { NavigationModule } from "@/app/types/store.type";
const routes = [
    {
        path: "/",
        name: "home",
        redirect: "order-list",
    },
    {
        path: "/fallback",
        name: "fallback",
        component: FallbackViewComponent,
    },
    {
        path: "/order-list",
        name: "order-list",
        component: OrderListComponent,
    },
    {
        path: "/:id",
        name: "id",
        component: UlpInformationComponent,
        beforeEnter: async (to) => {
            const path = to.params.id;
            if (!path || path === "/") {
                return false;
            }
            store.commit("summary/setActiveSummary", { id: path });
            let summary = store.getters["summary/getActive"];
            if (summary === undefined) {
                await store.dispatch("summary/fetchSummaryById", { id: path });
                if (store.state.summary.loadingErrorById[path]) {
                    return "order-list";
                }
                summary = store.getters["summary/getActive"];
                const laneId = summary.mainInformation.laneId;
                const creationDate = summary.mainInformation.creationDate;
                const filteredSummaryInfoIds = Object.fromEntries(Object.entries(store.state.summary.summaryInfoIds).filter(([key]) => key !== path));
                for (const [id, summaryInfo] of Object.entries(filteredSummaryInfoIds)) {
                    if (summaryInfo.laneId === laneId) {
                        if (moment(summaryInfo.creationDate).isAfter(moment(creationDate))) {
                            store.commit("summary/removeSummaryId", { id: path });
                            return { name: "id", params: { id: id } };
                        }
                        else {
                            store.commit("summary/removeSummaryId", { id: id });
                        }
                    }
                }
            }
            if (summary && moment().isSameOrAfter(summary?.mainInformation?.validityDate)) {
                return "order-list";
            }
            if (!summary.mainInformation.companyFromName) {
                store.commit("ui/setNavigationModules", NavigationModule.UnloadingPoint);
            }
            return true;
        },
    },
];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
export default router;
