import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-13a36406"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "empty-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SiteOpeningHours = _resolveComponent("SiteOpeningHours");
    const _component_UlpOpeningHours = _resolveComponent("UlpOpeningHours");
    return (_ctx.allDisabled())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.siteOpeningHoursHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_SiteOpeningHours, {
                    key: 0,
                    openingHours: _ctx.data.openingHours,
                    hasData: _ctx.siteOpeningHoursHasData()
                }, null, 8, ["openingHours", "hasData"]))
                : _createCommentVNode("", true),
            (_ctx.ulpOpeningHoursHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_UlpOpeningHours, {
                    key: 1,
                    openingHours: _ctx.data.unloadingLoadingHours,
                    hasData: _ctx.ulpOpeningHoursHasData()
                }, null, 8, ["openingHours", "hasData"]))
                : _createCommentVNode("", true)
        ], 64));
}
