import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["navigation-item", { disabled: _ctx.disabled }])
    }, [
        _createElementVNode("div", {
            class: "card",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.setNavigationSubmodule && _ctx.setNavigationSubmodule(...args)))
        }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isSvg ? 'SvgIcon' : 'Icon'), {
                class: "icon",
                name: _ctx.icon
            }, null, 8, ["name"])),
            _createElementVNode("p", null, _toDisplayString(_ctx.title), 1)
        ])
    ], 2));
}
