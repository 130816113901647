import { EnLocale, NavigationMenu, NavigationModule, NavigationSubmodule, SortOption } from "@/app/types/store.type";
export const UiModule = {
    namespaced: true,
    state() {
        return {
            navigationMenu: NavigationMenu.None,
            navigationModules: NavigationModule.LoadingPoint,
            navigationSubmodules: NavigationSubmodule.None,
            language: EnLocale,
            orderCardModalId: undefined,
            showIfNoData: false,
            sortOption: SortOption.MostRecent,
        };
    },
    mutations: {
        setNavigationMenu(state, payload) {
            document.getElementById("main")?.scrollTo(0, 0);
            state.navigationMenu = payload;
        },
        setNavigationModules(state, payload) {
            document.getElementById("main")?.scrollTo(0, 0);
            state.navigationModules = payload;
        },
        setNavigationSubmodules(state, payload) {
            document.getElementById("main")?.scrollTo(0, 0);
            state.navigationSubmodules = payload;
        },
        setLanguage(state, payload) {
            state.language = payload;
        },
        openOrderCardModal(state, payload) {
            state.orderCardModalId = payload;
        },
        closeOrderCardModal(state) {
            state.orderCardModalId = undefined;
        },
        setShowIfNoData(state, payload) {
            state.showIfNoData = payload;
        },
        setSortOption(state, payload) {
            state.sortOption = payload;
        },
    },
    actions: {
        setMenuOpen({ commit }, active) {
            commit("setNavigationMenu", active ? NavigationMenu.Menu : NavigationMenu.None);
        },
    },
};
