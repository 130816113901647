import { debounceTime, Subject, take } from "rxjs";
import { store } from "@/store/app.store";
const debounceServiceWorkerUpdateCheckDueTime = 1000;
const updateSubject = new Subject();
const checkForUpdateSubject = new Subject();
// requests to these routes will cause the service-worker to check for updates
const trackedRoutes = ["driverview"];
function onUpdate() {
    return updateSubject;
}
function onCheckForUpdate() {
    return checkForUpdateSubject;
}
function checkForUpdate() {
    checkForUpdateSubject.next();
}
function update() {
    updateSubject.next();
}
function setAppUpdatePending() {
    store.state.core.appUpdatePending = true;
}
function registerUpdateHandler(handler) {
    onUpdate()
        .pipe(take(1))
        .subscribe(() => handler());
}
function registerCheckForUpdateHandler(handler) {
    onCheckForUpdate()
        .pipe(debounceTime(debounceServiceWorkerUpdateCheckDueTime))
        .subscribe(() => handler());
}
export default {
    update,
    checkForUpdate,
    trackedRoutes,
    setAppUpdatePending,
    registerUpdateHandler,
    registerCheckForUpdateHandler,
};
