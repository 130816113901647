import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "pump" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = { class: "column-description" };
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_6 = {
    key: 1,
    class: "column-description"
};
const _hoisted_7 = { class: "column-header" };
const _hoisted_8 = { class: "column-description" };
const _hoisted_9 = { class: "column-header" };
const _hoisted_10 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.pump.name'),
            icon: "sanitizer",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('loady2go.no-data')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.pump.pumpToBeProvidedByCarrier !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.pump.pump-to-be-provided-by-carrier")), 1),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getTranslation(_ctx.pump.pumpToBeProvidedByCarrier, "yes", "no")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.pump.pumpTypes.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.pump.name")), 1),
                        (_ctx.pump.pumpTypes.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.pump.pumpTypes, 'PumpType'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.pump.powerSupply
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("sub-modules.pump.power-supply")), 1),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getValue(_ctx.pump.powerSupply, "PumpPowerSupply")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.pump.additionalPumpRequirements.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("sub-modules.pump.additional-pump-requirements")), 1),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getValues(_ctx.pump.additionalPumpRequirements, "PumpAdditionalRequirements")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
