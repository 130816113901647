const CONTEXT = "l2g-local";
const get = (key) => {
    const data = JSON.parse(localStorage.getItem(CONTEXT) ?? "{}");
    return data[key];
};
const set = (key, value) => {
    const data = JSON.parse(localStorage.getItem(CONTEXT) ?? "{}");
    data[key] = value;
    localStorage.setItem(CONTEXT, JSON.stringify(data));
};
export default { get, set };
