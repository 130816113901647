export const InfrastructuresNearbyInfo = [
    {
        translateKey: "sub-modules.nearby-infrastructures.petrol-label",
        nameValue: "petrolStation",
        iconName: "local_gas_station",
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.lng-label",
        nameValue: "lngStation",
        iconName: "local_gas_station",
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.e-charging-label",
        nameValue: "eChargingStation",
        iconName: "ev_station",
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.air-filling-label",
        nameValue: "airFillingStation",
        iconName: "air",
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.food-takeaway-label",
        nameValue: "foodTakeaway",
        iconName: "fastfood",
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.supermarket-label",
        nameValue: "supermarket",
        iconName: "local_grocery_store",
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.safety-equipment-store-label",
        nameValue: "safetyEquipmentStore",
        iconName: "health_and_safety",
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.kiosk-label",
        nameValue: "kiosk",
        iconName: "store",
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.pharmacy-label",
        nameValue: "pharmacy",
        iconName: "local_pharmacy",
    },
    {
        translateKey: "sub-modules.nearby-infrastructures.sanitary-facilities-label",
        nameValue: "sanitaryFacilities",
        iconName: "wash",
    },
];
