import axios from "axios";
import { checkForAppUpdateRequestInterceptor } from "@/app/core/interceptors/check-for-app-update.interceptor";
export class HttpService {
    axiosInstance;
    constructor() {
        const baseURL = process.env.VUE_APP_API_BASE_URL ?? "";
        const headers = {
            "Ocp-Apim-Subscription-Key": process.env.VUE_APP_API_SUBSCRIPTION_KEY ?? "",
        };
        this.axiosInstance = axios.create({
            baseURL,
            headers,
        });
        this.axiosInstance.interceptors.request.use(checkForAppUpdateRequestInterceptor);
    }
}
export const axiosInstance = new HttpService().axiosInstance;
