import axios from "axios";
import { axiosInstance } from "@/app/shared/services/http.service";
import localStorageService from "@/app/shared/services/local-storage.service";
import moment from "moment";
export const SummaryModule = {
    namespaced: true,
    state: () => ({
        summaryInfoIds: {},
        activeSummaryId: undefined,
        summaryById: {},
        summaryShortById: {},
        loadingStateById: {},
        loadingErrorById: {},
    }),
    mutations: {
        setActiveSummary(state, { id }) {
            state.activeSummaryId = id;
            localStorageService.set("activeSummaryId", id);
        },
        resetById(state, { id }) {
            delete state.loadingStateById[id];
            delete state.loadingErrorById[id];
        },
        addSummaryId(state, { id, laneId, creationDate }) {
            if (state.summaryInfoIds[id]) {
                return;
            }
            const summaryInfo = {
                laneId,
                creationDate,
                accessDate: moment(new Date().toISOString()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            };
            state.summaryInfoIds[id] = summaryInfo;
            localStorageService.set("summaryInfoIds", state.summaryInfoIds);
        },
        removeSummaryId(state, { id }) {
            if (!state.summaryInfoIds[id]) {
                return;
            }
            delete state.summaryInfoIds[id];
            delete state.summaryById[id];
            delete state.summaryShortById[id];
            localStorageService.set("summaryInfoIds", state.summaryInfoIds);
        },
        setIdsFromStorage(state) {
            const ids = localStorageService.get("summaryInfoIds");
            const active = localStorageService.get("activeSummaryId");
            if (ids) {
                Object.keys(ids).forEach((id) => {
                    state.summaryInfoIds[id] = ids[id];
                });
            }
            state.activeSummaryId = active;
        },
    },
    getters: {
        getActive: (state) => {
            const id = state.activeSummaryId ?? "";
            return state.summaryById[id];
        },
        getActiveSummaryValidityDate: (state, getters) => {
            return getters.getActive?.mainInformation?.validityDate;
        },
        getActiveSummaryBasic: (state, getters) => {
            return {
                productName: getters.getActive?.mainInformation?.productName,
                unloadedProductName: getters.getActive?.mainInformation?.unloadedProductName,
                siteFromName: getters.getActive?.mainInformation?.siteFromName,
                siteToName: getters.getActive?.mainInformation?.siteToName,
            };
        },
        getSummaryById: (state, { id }) => {
            return state.summaryById[id];
        },
        isLoadingById: (state, { id }) => {
            return state.loadingStateById[id] ?? false;
        },
        hasErrorById: (state, { id }) => {
            return !!state.loadingErrorById[id];
        },
    },
    actions: {
        async fetchSummaryById(context, { id }) {
            context.commit("resetById", id);
            context.state.loadingStateById[id] = true;
            try {
                const response = (await axiosInstance.get(`driverview/${id}`));
                context.state.summaryById[id] = response.data;
                const { laneId, creationDate } = response.data.mainInformation;
                context.commit("addSummaryId", { id, laneId, creationDate });
            }
            catch (e) {
                if (!axios.isAxiosError(e)) {
                    return;
                }
                context.state.loadingErrorById[id] = e;
            }
            finally {
                context.state.loadingStateById[id] = false;
            }
        },
        async fetchSummaryShortById(context, { id }) {
            context.commit("resetById", id);
            context.state.loadingStateById[id] = true;
            try {
                const response = (await axiosInstance.get(`driverview/mainInformation/${id}`));
                context.state.summaryShortById[id] = response.data;
                const { laneId, creationDate } = response.data.mainInformation;
                context.commit("addSummaryId", { id, laneId, creationDate });
            }
            catch (e) {
                if (!axios.isAxiosError(e)) {
                    return;
                }
                if (e?.response?.status === 404) {
                    context.commit("removeSummaryId", { id });
                }
                context.state.loadingErrorById[id] = e;
            }
            finally {
                context.state.loadingStateById[id] = false;
            }
        },
    },
};
