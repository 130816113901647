import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5611f13d"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "fallback-view" };
const _hoisted_2 = {
    key: 0,
    class: "description error"
};
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InputText = _resolveComponent("InputText");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.hasError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.isWrongId)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("loady2go.fallback-view.error-id")), 1))
                    : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("loady2go.fallback-view.error-else")), 1))
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("loady2go.fallback-view.description")), 1),
        _createVNode(_component_InputText, {
            class: "input",
            type: "text",
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.value) = $event))
        }, null, 8, ["modelValue"]),
        _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.navigateTo && _ctx.navigateTo(...args))),
            type: "button",
            class: "btn btn-primary"
        }, _toDisplayString(_ctx.$t("loady2go.fallback-view.button")), 1)
    ]));
}
