import { axiosInstance } from "@/app/shared/services/http.service";
export const setTranslation = (translationResult, dictionaryResult, language) => {
    if (translationResult.status === "fulfilled" && dictionaryResult.status === "fulfilled") {
        const dictionary = dictionaryResult.value?.data;
        for (const key in dictionary) {
            dictionary["dictionary." + key] = dictionary[key];
            delete dictionary[key];
        }
        translationService.translations[language] = {
            ...translationResult.value?.data,
            ...dictionary,
        };
    }
};
export class TranslationService {
    translations = {};
    async fetchTranslation(locale) {
        const urlPath = `/translations/LOADYFRONTEND.${locale.toUpperCase()}.json`;
        const url = new URL(urlPath, process.env.VUE_APP_TRANSLATIONS_BASE_URL);
        return await axiosInstance.get(url.toString());
    }
    async fetchDictionary(locale) {
        const urlPath = `/translations/LOADYBACKEND.${locale.toUpperCase()}.json`;
        const url = new URL(urlPath, process.env.VUE_APP_TRANSLATIONS_BASE_URL);
        return await axiosInstance.get(url.toString());
    }
}
export const translationService = new TranslationService();
