import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4089750d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "install-prompt"
};
const _hoisted_2 = { class: "prompt-container" };
const _hoisted_3 = { class: "prompt-message" };
const _hoisted_4 = { class: "buttons-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.deferredPrompt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("loady2go.install-prompt.message")), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("button", {
                        onClick: _cache[0] || (_cache[0] =
                            //@ts-ignore
                            (...args) => (_ctx.installPWA && _ctx.installPWA(...args))),
                        type: "button",
                        class: "btn btn-primary"
                    }, _toDisplayString(_ctx.$t("loady2go.install-prompt.yes")), 1),
                    _createElementVNode("button", {
                        onClick: _cache[1] || (_cache[1] =
                            //@ts-ignore
                            (...args) => (_ctx.dismissPrompt && _ctx.dismissPrompt(...args))),
                        type: "button",
                        class: "btn btn-warning"
                    }, _toDisplayString(_ctx.$t("loady2go.install-prompt.no")), 1)
                ])
            ])
        ]))
        : _createCommentVNode("", true);
}
