import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-523edf54"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "empty-description"
};
const _hoisted_2 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GeneralInstructions = _resolveComponent("GeneralInstructions");
    const _component_Weighing = _resolveComponent("Weighing");
    const _component_Filling = _resolveComponent("Filling");
    const _component_Sealing = _resolveComponent("Sealing");
    const _component_Sampling = _resolveComponent("Sampling");
    const _component_Processes = _resolveComponent("Processes");
    return (!_ctx.isProductAvailable || _ctx.allDisabled())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.generalInstructionsHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_GeneralInstructions, {
                    key: 0,
                    generalInstructions: _ctx.data.generalInstructions,
                    hasData: _ctx.generalInstructionsHasData()
                }, null, 8, ["generalInstructions", "hasData"]))
                : _createCommentVNode("", true),
            (_ctx.weighingHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_Weighing, {
                    key: 1,
                    weighing: _ctx.data.weighing,
                    hasData: _ctx.weighingHasData()
                }, null, 8, ["weighing", "hasData"]))
                : _createCommentVNode("", true),
            (_ctx.fillingHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_Filling, {
                    key: 2,
                    filling: _ctx.data.filling,
                    hasData: _ctx.fillingHasData()
                }, null, 8, ["filling", "hasData"]))
                : _createCommentVNode("", true),
            (_ctx.sealingHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_Sealing, {
                    key: 3,
                    sealing: _ctx.data.sealing,
                    hasData: _ctx.sealingHasData()
                }, null, 8, ["sealing", "hasData"]))
                : _createCommentVNode("", true),
            (_ctx.samplingHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_Sampling, {
                    key: 4,
                    sampling: _ctx.data.sampling,
                    hasData: _ctx.samplingHasData()
                }, null, 8, ["sampling", "hasData"]))
                : _createCommentVNode("", true),
            (_ctx.processesHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_Processes, {
                    key: 5,
                    processes: _ctx.data.processes,
                    hasData: _ctx.processesHasData()
                }, null, 8, ["processes", "hasData"]))
                : _createCommentVNode("", true)
        ]));
}
