import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-16f46b6d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "empty-description"
};
const _hoisted_2 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Vehicle = _resolveComponent("Vehicle");
    const _component_Tank = _resolveComponent("Tank");
    const _component_Connections = _resolveComponent("Connections");
    const _component_Hose = _resolveComponent("Hose");
    const _component_Pump = _resolveComponent("Pump");
    const _component_Compressor = _resolveComponent("Compressor");
    const _component_VapourReturn = _resolveComponent("VapourReturn");
    const _component_TemperatureRequirement = _resolveComponent("TemperatureRequirement");
    const _component_EquipmentPacked = _resolveComponent("EquipmentPacked");
    const _component_CargoSecuring = _resolveComponent("CargoSecuring");
    return (!_ctx.isProductAvailable || _ctx.allDisabled())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.productType === 'Bulk')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.vehicleHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_Vehicle, {
                            key: 0,
                            vehicle: _ctx.data.vehicle,
                            hasData: _ctx.vehicleHasData()
                        }, null, 8, ["vehicle", "hasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.tankHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_Tank, {
                            key: 1,
                            tank: _ctx.data.tank,
                            hasData: _ctx.tankHasData()
                        }, null, 8, ["tank", "hasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.connectionsHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_Connections, {
                            key: 2,
                            connections: _ctx.data.connections,
                            hasData: _ctx.connectionsHasData()
                        }, null, 8, ["connections", "hasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.hoseHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_Hose, {
                            key: 3,
                            hose: _ctx.data.hose,
                            hasData: _ctx.hoseHasData()
                        }, null, 8, ["hose", "hasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.pumpHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_Pump, {
                            key: 4,
                            pump: _ctx.data.pump,
                            hasData: _ctx.pumpHasData()
                        }, null, 8, ["pump", "hasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.compressorHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_Compressor, {
                            key: 5,
                            compressor: _ctx.data.compressor,
                            hasData: _ctx.compressorHasData()
                        }, null, 8, ["compressor", "hasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.vapourReturnHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_VapourReturn, {
                            key: 6,
                            vapourReturn: _ctx.data.vapourReturn,
                            hasData: _ctx.vapourReturnHasData()
                        }, null, 8, ["vapourReturn", "hasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.temperatureRequirementHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_TemperatureRequirement, {
                            key: 7,
                            temperatureRequirement: _ctx.data.temperatureRequirement,
                            hasData: _ctx.temperatureRequirementHasData()
                        }, null, 8, ["temperatureRequirement", "hasData"]))
                        : _createCommentVNode("", true)
                ], 64))
                : _createCommentVNode("", true),
            (_ctx.productType === 'Packed')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.vehicleHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_Vehicle, {
                            key: 0,
                            vehicle: _ctx.data.vehicle,
                            hasData: _ctx.vehicleHasData()
                        }, null, 8, ["vehicle", "hasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.equipmentPackedHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_EquipmentPacked, {
                            key: 1,
                            equipmentPacked: _ctx.data.equipmentPacked,
                            hasData: _ctx.equipmentPackedHasData()
                        }, null, 8, ["equipmentPacked", "hasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.cargoSecuringHasData() ||
                        _ctx.certifiedCargoSecuringSystemHasData() ||
                        _ctx.slatBoardsHasData() ||
                        _ctx.antiSlipMatsHasData() ||
                        _ctx.lashingHasData() ||
                        _ctx.disposalOfCargoSecuringMaterialHasData() ||
                        _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_CargoSecuring, {
                            key: 2,
                            cargoSecuring: _ctx.data.cargoSecuring,
                            hasData: _ctx.cargoSecuringHasData(),
                            certifiedCargoSecuringSystemHasData: _ctx.certifiedCargoSecuringSystemHasData(),
                            slatBoardsHasData: _ctx.slatBoardsHasData(),
                            antiSlipMatsHasData: _ctx.antiSlipMatsHasData(),
                            lashingHasData: _ctx.lashingHasData(),
                            disposalOfCargoSecuringMaterialHasData: _ctx.disposalOfCargoSecuringMaterialHasData()
                        }, null, 8, ["cargoSecuring", "hasData", "certifiedCargoSecuringSystemHasData", "slatBoardsHasData", "antiSlipMatsHasData", "lashingHasData", "disposalOfCargoSecuringMaterialHasData"]))
                        : _createCommentVNode("", true),
                    (_ctx.temperatureRequirementHasData() || _ctx.areFieldsShown)
                        ? (_openBlock(), _createBlock(_component_TemperatureRequirement, {
                            key: 3,
                            temperatureRequirement: _ctx.data.temperatureRequirement,
                            hasData: _ctx.temperatureRequirementHasData()
                        }, null, 8, ["temperatureRequirement", "hasData"]))
                        : _createCommentVNode("", true)
                ], 64))
                : _createCommentVNode("", true)
        ]));
}
