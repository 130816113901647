import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b1b59fb"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "truck-widget" };
const _hoisted_2 = {
    key: 0,
    class: "truck-box"
};
const _hoisted_3 = { class: "truck-title" };
const _hoisted_4 = { class: "svg-wrapper" };
const _hoisted_5 = { class: "checkboxes-wrapper" };
const _hoisted_6 = { class: "checkbox" };
const _hoisted_7 = {
    key: 1,
    class: "truck-box"
};
const _hoisted_8 = { class: "truck-title" };
const _hoisted_9 = { class: "svg-wrapper" };
const _hoisted_10 = { class: "checkboxes-wrapper" };
const _hoisted_11 = { class: "checkbox" };
const _hoisted_12 = { class: "checkbox" };
const _hoisted_13 = { class: "checkbox" };
const _hoisted_14 = {
    key: 2,
    class: "truck-box"
};
const _hoisted_15 = { class: "truck-title" };
const _hoisted_16 = { class: "svg-wrapper" };
const _hoisted_17 = { class: "checkboxes-wrapper" };
const _hoisted_18 = { class: "checkbox" };
const _hoisted_19 = {
    key: 3,
    class: "truck-box"
};
const _hoisted_20 = { class: "truck-title" };
const _hoisted_21 = { class: "svg-wrapper" };
const _hoisted_22 = { class: "checkboxes-wrapper" };
const _hoisted_23 = { class: "checkbox" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_TruckTop = _resolveComponent("TruckTop");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_TruckRear = _resolveComponent("TruckRear");
    const _component_TruckLeft = _resolveComponent("TruckLeft");
    const _component_TruckRight = _resolveComponent("TruckRight");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.truckArray.includes('Top') || _ctx.$store.state.ui.showIfNoData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.$t("sub-modules.connections.truck-top-view")), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_TruckTop, { "truck-connections": _ctx.truckArray }, null, 8, ["truck-connections"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_AppCheckbox, {
                            "model-value": _ctx.truckArray.includes('Top')
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t("sub-modules.connections.a-top-label")), 1)
                            ]),
                            _: 1
                        }, 8, ["model-value"])
                    ])
                ])
            ]))
            : _createCommentVNode("", true),
        (['LeftSide', 'Middle', 'RightSide'].some((el) => _ctx.truckArray.includes(el)) || _ctx.$store.state.ui.showIfNoData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("h6", _hoisted_8, _toDisplayString(_ctx.$t("sub-modules.connections.truck-rear-view")), 1),
                _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_TruckRear, { "truck-connections": _ctx.truckArray }, null, 8, ["truck-connections"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_AppCheckbox, {
                            "model-value": _ctx.truckArray.includes('LeftSide')
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t("sub-modules.connections.b-left-side-label")), 1)
                            ]),
                            _: 1
                        }, 8, ["model-value"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_AppCheckbox, {
                            "model-value": _ctx.truckArray.includes('Middle')
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t("sub-modules.connections.c-middle-label")), 1)
                            ]),
                            _: 1
                        }, 8, ["model-value"])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_AppCheckbox, {
                            "model-value": _ctx.truckArray.includes('RightSide')
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t("sub-modules.connections.d-right-side-label")), 1)
                            ]),
                            _: 1
                        }, 8, ["model-value"])
                    ])
                ])
            ]))
            : _createCommentVNode("", true),
        (_ctx.truckArray.includes('SideLeft') || _ctx.$store.state.ui.showIfNoData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("h6", _hoisted_15, _toDisplayString(_ctx.$t("sub-modules.connections.truck-side-view-left")), 1),
                _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_TruckLeft, { "truck-connections": _ctx.truckArray }, null, 8, ["truck-connections"])
                ]),
                _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_AppCheckbox, {
                            "model-value": _ctx.truckArray.includes('SideLeft')
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t("sub-modules.connections.e-side-left-label")), 1)
                            ]),
                            _: 1
                        }, 8, ["model-value"])
                    ])
                ])
            ]))
            : _createCommentVNode("", true),
        (_ctx.truckArray.includes('SideRight') || _ctx.$store.state.ui.showIfNoData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("h6", _hoisted_20, _toDisplayString(_ctx.$t("sub-modules.connections.truck-side-view-right")), 1),
                _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_TruckRight, { "truck-connections": _ctx.truckArray }, null, 8, ["truck-connections"])
                ]),
                _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                        _createVNode(_component_AppCheckbox, {
                            "model-value": _ctx.truckArray.includes('SideRight')
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t("sub-modules.connections.f-side-right-label")), 1)
                            ]),
                            _: 1
                        }, 8, ["model-value"])
                    ])
                ])
            ]))
            : _createCommentVNode("", true)
    ]));
}
