import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "filling" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = { class: "column-description" };
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = { class: "column-description" };
const _hoisted_6 = { class: "column-header" };
const _hoisted_7 = { class: "column-description" };
const _hoisted_8 = { class: "column-header" };
const _hoisted_9 = { class: "column-description" };
const _hoisted_10 = { class: "column-header" };
const _hoisted_11 = { class: "column-description" };
const _hoisted_12 = { class: "column-header" };
const _hoisted_13 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.filling.name'),
            icon: "invert_colors",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('global.phrases.no-special-requirements')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.filling.reactorLoading !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.filling.reactor-loading")), 1),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getReactorLoadingValue(_ctx.filling.reactorLoading)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.filling.fillingRule
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.filling.filling-rule")), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getValue(_ctx.filling.fillingRule, "FillingRule")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.filling.maxFillingGrade !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("sub-modules.filling.maximum-filling-grade-wo-baffles")), 1),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getPercentageValue(_ctx.filling.maxFillingGrade)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.filling.minFillingGrade !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("sub-modules.filling.minimum-filling-grade-wo-baffles")), 1),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getPercentageValue(_ctx.filling.minFillingGrade)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.filling.maxTechnicalFillingGrade !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.filling.maximum-technical-grade")), 1),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getPercentageValue(_ctx.filling.maxTechnicalFillingGrade)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.filling.additionalRequirements)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("sub-modules.filling.additional-filling-requirements")), 1),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.getLocaleValue(_ctx.filling.additionalRequirements)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
