import { openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = {
    key: 0,
    class: "column-description"
};
const _hoisted_3 = {
    key: 1,
    class: "column-description"
};
const _hoisted_4 = { key: 1 };
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = { class: "column-description" };
const _hoisted_7 = { class: "column-header" };
const _hoisted_8 = { class: "column-description" };
const _hoisted_9 = { class: "column-header" };
const _hoisted_10 = { class: "column-description" };
const _hoisted_11 = { class: "column-header" };
const _hoisted_12 = { class: "column-description" };
const _hoisted_13 = { class: "column-header" };
const _hoisted_14 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    return (_ctx.short)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.isShortAddressEmpty)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.shortAddress), 1))
        ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.address.streetAddress
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("global.form-fields.street-address.label")), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.address.streetAddress ?? "-"), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.address.addressAddition
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("global.form-fields.address-addition.label")), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.address.addressAddition ?? "-"), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.address.postalCode
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("global.form-fields.postal-code.label")), 1),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.address.postalCode ?? "-"), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.address.addressLocality
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("global.form-fields.address-locality.label")), 1),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.address.addressLocality ?? "-"), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.address.countryCode
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("global.form-fields.country.label")), 1),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.country), 1)
                ]),
                _: 1
            }, 8, ["hasData"])
        ]));
}
