import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "column-title" };
const _hoisted_2 = {
    key: 0,
    class: "column-description"
};
const _hoisted_3 = { class: "column-header" };
const _hoisted_4 = { class: "column-description" };
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = { class: "column-description" };
const _hoisted_7 = { class: "column-header" };
const _hoisted_8 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("sub-modules.online-check-in.name")), 1),
        (!_ctx.hasData() && !_ctx.$store.state.ui.showIfNoData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
            : _createCommentVNode("", true),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.onlineCheckIn.onlineCheckInRequirement
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("sub-modules.online-check-in.online-check-in-requirement")), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getValue(_ctx.onlineCheckIn.onlineCheckInRequirement, "OnlineCheckInRequirement")), 1)
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_SubmoduleField, {
            hasData: _ctx.onlineCheckIn.onlineCheckInLatest > 0
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("sub-modules.online-check-in.check-in-latest")), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.checkInLatestInfo), 1)
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.onlineCheckInProceduresLocalised.length
        }, {
            default: _withCtx(() => [
                _createVNode(_component_DisplayAttachments, {
                    attachments: _ctx.onlineCheckInProceduresLocalised,
                    title: _ctx.$t('sub-modules.online-check-in.online-check-in-procedure'),
                    class: "mb-3"
                }, null, 8, ["attachments", "title"])
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.onlineCheckIn.languagesOnlineProcedures.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("sub-modules.online-check-in.languages-procedure-title")), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.languagesInfo), 1)
            ]),
            _: 1
        }, 8, ["hasData"])
    ], 64));
}
