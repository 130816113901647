import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-023c6da1"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "cargo-securing" };
const _hoisted_2 = { class: "column-title" };
const _hoisted_3 = {
    key: 0,
    class: "column-description"
};
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = { class: "column-description" };
const _hoisted_6 = { class: "column-title" };
const _hoisted_7 = {
    key: 1,
    class: "column-description"
};
const _hoisted_8 = { class: "column-header" };
const _hoisted_9 = { class: "column-description" };
const _hoisted_10 = { class: "column-title" };
const _hoisted_11 = {
    key: 2,
    class: "column-description"
};
const _hoisted_12 = {
    key: 3,
    class: "column-header sub-title"
};
const _hoisted_13 = { class: "column-header" };
const _hoisted_14 = { class: "column-description" };
const _hoisted_15 = { class: "column-header" };
const _hoisted_16 = { class: "column-description" };
const _hoisted_17 = { class: "column-header" };
const _hoisted_18 = { class: "column-description" };
const _hoisted_19 = { class: "column-header" };
const _hoisted_20 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_21 = {
    key: 1,
    class: "column-description"
};
const _hoisted_22 = { class: "column-header" };
const _hoisted_23 = { class: "column-description" };
const _hoisted_24 = {
    key: 4,
    class: "column-header sub-title"
};
const _hoisted_25 = { class: "column-header" };
const _hoisted_26 = { class: "column-description" };
const _hoisted_27 = { class: "column-header" };
const _hoisted_28 = { class: "column-description" };
const _hoisted_29 = { class: "column-header" };
const _hoisted_30 = { class: "column-description" };
const _hoisted_31 = { class: "column-header" };
const _hoisted_32 = { class: "column-description" };
const _hoisted_33 = { class: "column-header" };
const _hoisted_34 = { class: "column-description" };
const _hoisted_35 = {
    key: 5,
    class: "column-header sub-title"
};
const _hoisted_36 = { class: "column-header" };
const _hoisted_37 = { class: "column-description" };
const _hoisted_38 = { class: "column-header" };
const _hoisted_39 = { class: "column-description" };
const _hoisted_40 = { class: "column-header" };
const _hoisted_41 = { class: "column-description" };
const _hoisted_42 = { class: "column-header" };
const _hoisted_43 = { class: "column-description" };
const _hoisted_44 = { class: "column-header" };
const _hoisted_45 = { class: "column-description" };
const _hoisted_46 = { class: "column-header" };
const _hoisted_47 = { class: "column-description" };
const _hoisted_48 = { class: "column-header" };
const _hoisted_49 = { class: "column-description" };
const _hoisted_50 = { class: "column-header" };
const _hoisted_51 = { class: "column-description" };
const _hoisted_52 = {
    key: 7,
    class: "column-header sub-title"
};
const _hoisted_53 = { class: "column-header" };
const _hoisted_54 = { class: "column-description" };
const _hoisted_55 = { class: "column-header" };
const _hoisted_56 = { class: "column-description" };
const _hoisted_57 = { class: "column-header" };
const _hoisted_58 = { class: "column-description" };
const _hoisted_59 = { class: "column-header" };
const _hoisted_60 = { class: "column-description" };
const _hoisted_61 = { class: "column-header" };
const _hoisted_62 = { class: "column-description" };
const _hoisted_63 = { class: "column-header" };
const _hoisted_64 = { class: "column-description" };
const _hoisted_65 = { class: "column-header" };
const _hoisted_66 = { class: "column-description" };
const _hoisted_67 = { class: "column-header" };
const _hoisted_68 = { class: "column-description" };
const _hoisted_69 = {
    key: 9,
    class: "column-header sub-title"
};
const _hoisted_70 = { class: "column-header" };
const _hoisted_71 = { class: "column-description" };
const _hoisted_72 = { class: "column-header" };
const _hoisted_73 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.cargo-securing.name'),
            icon: "vpn_lock",
            hasData: _ctx.hasData ||
                _ctx.certifiedCargoSecuringSystemHasData ||
                _ctx.slatBoardsHasData ||
                _ctx.antiSlipMatsHasData ||
                _ctx.lashingHasData ||
                _ctx.disposalOfCargoSecuringMaterialHasData,
            noDataMsg: _ctx.$t('global.phrases.no-special-requirements')
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-requirements-below-3-5-t")), 1),
                (!_ctx.hasLocalisedText(_ctx.cargoSecuring.minimumRequirementsForVehiclesBelowThreeAndHalfTonsGVWR) &&
                    !_ctx.$store.state.ui.showIfNoData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
                    : _createCommentVNode("", true),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.cargoSecuring.minimumRequirementsForVehiclesBelowThreeAndHalfTonsGVWR)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-requirements-below-3-5-t")), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getLocaleValue(_ctx.cargoSecuring.minimumRequirementsForVehiclesBelowThreeAndHalfTonsGVWR)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-requirements-above-3-5-t")), 1),
                (!_ctx.hasLocalisedText(_ctx.cargoSecuring.minimumRequirementsForVehiclesAboveThreeAndHalfTonsGVWR) &&
                    !_ctx.$store.state.ui.showIfNoData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
                    : _createCommentVNode("", true),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.cargoSecuring.minimumRequirementsForVehiclesAboveThreeAndHalfTonsGVWR)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-requirements-above-3-5-t")), 1),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getLocaleValue(_ctx.cargoSecuring.minimumRequirementsForVehiclesAboveThreeAndHalfTonsGVWR)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.cargo-securing-material")), 1),
                (!_ctx.certifiedCargoSecuringSystemHasData &&
                    !_ctx.slatBoardsHasData &&
                    !_ctx.antiSlipMatsHasData &&
                    !_ctx.lashingHasData &&
                    !_ctx.disposalOfCargoSecuringMaterialHasData &&
                    !_ctx.$store.state.ui.showIfNoData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
                    : _createCommentVNode("", true),
                (_ctx.certifiedCargoSecuringSystemHasData || _ctx.$store.state.ui.showIfNoData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.certified-cargo-securing-system")), 1))
                    : _createCommentVNode("", true),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.isRequired
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.certified-cargo-securing-system")), 1),
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getDictionaryValue(_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.isRequired, "CertifiedCargoSecuringSystemRequired")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.isRequired === 'RequiredOnlyInCaseOf'
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.required-only-in-case-of")), 1),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.getValue(_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.requiredOnlyInCaseOf)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.providedBy
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.system-to-be-provided-by")), 1),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getDictionaryValue(_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.systemSpecification?.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.system-specification")), 1),
                        (_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.systemSpecification?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDictionaryValues(_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.systemSpecification, 'SystemSpecification'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_21, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.additionalInformation)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.additional-information")), 1),
                        _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.getLocaleValue(_ctx.cargoSecuring.cargoSecuringMaterial.certifiedCargoSecuringSystem.additionalInformation)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                (_ctx.slatBoardsHasData || _ctx.$store.state.ui.showIfNoData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.slat-boards")), 1))
                    : _createCommentVNode("", true),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.providedBy
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.slat-boards-to-be-provided-by")), 1),
                        _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.getDictionaryValue(_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.tarpaulinTrailers)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.tarpaulin-trailers")), 1),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.getLocaleValue(_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.tarpaulinTrailers)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.curtainSideTrailers)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.slat-boards-curtain-side-trailers")), 1),
                        _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.getLocaleValue(_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.curtainSideTrailers)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.tarpaulinSuperstructures)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.tarpaulin-superstructures")), 1),
                        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.getLocaleValue(_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.tarpaulinSuperstructures)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.boxTruck)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.box-truck")), 1),
                        _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.getLocaleValue(_ctx.cargoSecuring.cargoSecuringMaterial.slatBoards.boxTruck)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                (_ctx.antiSlipMatsHasData || _ctx.$store.state.ui.showIfNoData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_35, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.anti-slip-mats")), 1))
                    : _createCommentVNode("", true),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.providedBy
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.anti-slip-mats-to-be-provided-by")), 1),
                        _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.getDictionaryValue(_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.carrierToEnsureSufficientNumberOfAntiSlipMatsToSecureCargo
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.carrier-ensures-number-of-anti-slip-mats")), 1),
                        _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.getDictionaryValue(_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats
                            .carrierToEnsureSufficientNumberOfAntiSlipMatsToSecureCargo, "CarrierDecidesAboutNumberOfToSecuringCargo")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                (_ctx.cargoSecuring?.cargoSecuringMaterial?.antiSlipMats
                    ?.carrierToEnsureSufficientNumberOfAntiSlipMatsToSecureCargo === 'FollowCompanyGuideline')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumNumberPerPallet !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-number-per-pallet")), 1),
                                _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumNumberPerPallet ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"]),
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumNumberPerTruck !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-number-per-truck")), 1),
                                _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumNumberPerTruck ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"]),
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumLengthSize !== null ||
                                _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumWidthSize !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-size")), 1),
                                _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.getDimensions(_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumLengthSize, _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumWidthSize)), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"]),
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.alternativeLengthSize !== null ||
                                _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.alternativeWidthSize !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.alt-size")), 1),
                                _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.getDimensions(_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.alternativeLengthSize, _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.alternativeWidthSize)), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"]),
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumThickness !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-thickness")), 1),
                                _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.minimumThickness, "global.measurement.millimeter")), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"]),
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.frictionCoefcient !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.friction-coeficient")), 1),
                                _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.cargoSecuring.cargoSecuringMaterial.antiSlipMats.frictionCoefcient, "global.measurement.micro")), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"])
                    ], 64))
                    : _createCommentVNode("", true),
                (_ctx.lashingHasData || _ctx.$store.state.ui.showIfNoData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_52, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.lashing")), 1))
                    : _createCommentVNode("", true),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.cargoSecuring.cargoSecuringMaterial.lashing.providedBy
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.lashing-to-be-provided-by")), 1),
                        _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.getDictionaryValue(_ctx.cargoSecuring.cargoSecuringMaterial.lashing.providedBy, "LoadSecuringMaterialProvidedBy")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.cargoSecuring.cargoSecuringMaterial.lashing.dinNorm)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.din-compliance")), 1),
                        _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.getLocaleValue(_ctx.cargoSecuring.cargoSecuringMaterial.lashing.dinNorm)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.cargoSecuring.cargoSecuringMaterial.lashing.carrierToEnsureSufficientNumberOfLashingsToSecureCargo
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.carrier-ensures-number-of-lashing")), 1),
                        _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.getDictionaryValue(_ctx.cargoSecuring.cargoSecuringMaterial.lashing.carrierToEnsureSufficientNumberOfLashingsToSecureCargo, "CarrierDecidesAboutNumberOfToSecuringCargo")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                (_ctx.cargoSecuring?.cargoSecuringMaterial?.lashing?.carrierToEnsureSufficientNumberOfLashingsToSecureCargo ===
                    'FollowCompanyGuideline')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumNumberPerPallet !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-number-per-pallet")), 1),
                                _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumNumberPerPallet ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"]),
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumNumberPerTruck !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-number-per-truck")), 1),
                                _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumNumberPerTruck ?? "-"), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"]),
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumLength !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-length")), 1),
                                _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumLength, "global.measurement.meter")), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"]),
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumLashingCapacity !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-lashing-capacity")), 1),
                                _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumLashingCapacity, "global.measurement.decanewton")), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"]),
                        _createVNode(_component_SubmoduleField, {
                            hasData: _ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumTensionForceSTF !== null
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_67, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.min-tension-force")), 1),
                                _createElementVNode("div", _hoisted_68, _toDisplayString(_ctx.getNumericValueWithUnit(_ctx.cargoSecuring.cargoSecuringMaterial.lashing.minimumTensionForceSTF, "global.measurement.decanewton")), 1)
                            ]),
                            _: 1
                        }, 8, ["hasData"])
                    ], 64))
                    : _createCommentVNode("", true),
                (_ctx.disposalOfCargoSecuringMaterialHasData || _ctx.$store.state.ui.showIfNoData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_69, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.disposal-of-cargo-securing-material")), 1))
                    : _createCommentVNode("", true),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.cargoSecuring.cargoSecuringMaterial.disposalOfCargoSecuringMaterial
                        .instructionsToDisposeTheSecuringMaterialAfterUnloading
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.carrier-ensures-disposal-instructions")), 1),
                        _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.getDictionaryValue(_ctx.cargoSecuring.cargoSecuringMaterial.disposalOfCargoSecuringMaterial
                            .instructionsToDisposeTheSecuringMaterialAfterUnloading, "DisposalOfSecuringMaterial")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.cargoSecuring.cargoSecuringMaterial.disposalOfCargoSecuringMaterial.additionalInformation)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.$t("sub-modules.cargo-securing.additional-information")), 1),
                        _createElementVNode("div", _hoisted_73, _toDisplayString(_ctx.getLocaleValue(_ctx.cargoSecuring.cargoSecuringMaterial.disposalOfCargoSecuringMaterial.additionalInformation)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
