import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "column-header" };
const _hoisted_2 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_3 = {
    key: 1,
    class: "column-description"
};
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_6 = {
    key: 1,
    class: "column-description"
};
const _hoisted_7 = { class: "column-header" };
const _hoisted_8 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_9 = {
    key: 1,
    class: "column-description"
};
const _hoisted_10 = { class: "column-header" };
const _hoisted_11 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_12 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('loady2go.sub-modules.ppe-requirements.name'),
        icon: "masks",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('sub-modules.vehicle-and-personal-protective-equipment.no-special-ppe-requirements')
    }, {
        default: _withCtx(() => [
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.ppeRequirement.minPPEToEnterSite.length
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("sub-modules.vehicle-and-personal-protective-equipment.minimum-ppe-requirements.label")), 1),
                    (_ctx.ppeRequirement.minPPEToEnterSite.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.ppeRequirement.minPPEToEnterSite, 'VehicleAndPersonalProtectiveEquipment'), (item) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: item,
                                    class: "chip"
                                }, _toDisplayString(item), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_3, "-"))
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.ppeRequirement.additionalPPEForDGGoods.length
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.vehicle-and-personal-protective-equipment.additional-ppe-requirements.label")), 1),
                    (_ctx.ppeRequirement.additionalPPEForDGGoods.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.ppeRequirement.additionalPPEForDGGoods, 'VehicleAndPersonalProtectiveEquipment'), (item) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: item,
                                    class: "chip"
                                }, _toDisplayString(item), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                "has-data": !!_ctx.ppeRequirement.additionalULPSpecificPPErequirements?.length
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t(`sub-modules.vehicle-and-personal-protective-equipment.un-loading-point-specific-requirements.${_ctx.domainPrefix}-label`)), 1),
                    (_ctx.ppeRequirement.additionalULPSpecificPPErequirements?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.ppeRequirement.additionalULPSpecificPPErequirements, 'VehicleAndPersonalProtectiveEquipment'), (item) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: item,
                                    class: "chip"
                                }, _toDisplayString(item), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.ppeRequirement.additionalULPSpecificPPErequirements === undefined ? _ctx.$t("loady2go.no-data") : "-"), 1))
                ]),
                _: 1
            }, 8, ["has-data"]),
            _createVNode(_component_SubmoduleField, {
                "has-data": !!_ctx.ppeRequirement.additionalAssignedProductSpecificPPErequirements?.length
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.vehicle-and-personal-protective-equipment.additional-product-ppe-requirements.label")), 1),
                    (_ctx.ppeRequirement.additionalAssignedProductSpecificPPErequirements?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.ppeRequirement.additionalAssignedProductSpecificPPErequirements, 'VehicleAndPersonalProtectiveEquipment'), (item) => {
                                return (_openBlock(), _createElementBlock("span", {
                                    key: item,
                                    class: "chip"
                                }, _toDisplayString(item), 1));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.ppeRequirement.additionalAssignedProductSpecificPPErequirements === undefined ? _ctx.$t("loady2go.no-data") : "-"), 1))
                ]),
                _: 1
            }, 8, ["has-data"])
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg"]));
}
