import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bad01bb8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "column-description"
};
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = {
    key: 1,
    class: "column-description"
};
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = {
    key: 1,
    class: "column-description"
};
const _hoisted_7 = { class: "column-header" };
const _hoisted_8 = { key: 0 };
const _hoisted_9 = {
    key: 1,
    class: "column-description"
};
const _hoisted_10 = { class: "column-header" };
const _hoisted_11 = { key: 0 };
const _hoisted_12 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_MapsButton = _resolveComponent("MapsButton");
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (!_ctx.hasData() && !_ctx.$store.state.ui.showIfNoData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
            : _createCommentVNode("", true),
        _createVNode(_component_SubmoduleField, {
            hasData: _ctx.hasLocation(_ctx.data.location)
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.parking-space.parking-space-location")), 1),
                (_ctx.hasLocation(_ctx.data.location))
                    ? (_openBlock(), _createBlock(_component_MapsButton, {
                        key: 0,
                        location: _ctx.data.location,
                        class: "mb-3"
                    }, null, 8, ["location"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, "-"))
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.data.purposeOfUse.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.parking-space.purpose-of-use")), 1),
                (_ctx.data.purposeOfUse.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.purposeOfUse, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: item + index,
                                class: "column-description description-item"
                            }, _toDisplayString(_ctx.getPurposeValue(item, _ctx.data)), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, "-"))
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.data.safetyMeasures.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("sub-modules.parking-space.safety-measures")), 1),
                (_ctx.data.safetyMeasures.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.safetyMeasures, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: item + index,
                                class: "column-description description-item"
                            }, _toDisplayString(_ctx.getValue(item, "SafetyMeasure")), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, "-"))
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.data.safetyMeasures.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.parking-space.sanitary-facilities")), 1),
                (_ctx.data.sanitaryFacilities.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.sanitaryFacilities, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: item + index,
                                class: "column-description description-item"
                            }, _toDisplayString(_ctx.getValue(item, "SanitaryFacility")), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_12, "-"))
            ]),
            _: 1
        }, 8, ["hasData"])
    ], 64));
}
