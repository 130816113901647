import _ from "lodash";
export const useDataValidation = (data, validators) => {
    const hasData = () => {
        const keys = _.keys(data);
        return keys
            .map((key) => {
            const validator = validators ? validators[key] : undefined;
            const value = data[key];
            if (validator) {
                return validator(value);
            }
            if (typeof value === "boolean") {
                return true;
            }
            if (typeof value === "number") {
                return value !== 0;
            }
            if (!value || value === "" || _.isEmpty(value)) {
                return false;
            }
            return true;
        })
            .some((it) => it);
    };
    return {
        hasData,
    };
};
