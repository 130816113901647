import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5aeeccbc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "sub-module" };
const _hoisted_2 = { class: "title" };
const _hoisted_3 = {
    key: 0,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isSvg ? 'SvgIcon' : 'Icon'), {
                class: "icon",
                name: _ctx.icon
            }, null, 8, ["name"])),
            _createElementVNode("p", null, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", null, [
            (_ctx.hasData === false && (!_ctx.$store.state.ui.showIfNoData || _ctx.showNoDataMsg))
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.noDataMsg), 1))
                : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
        ])
    ]));
}
