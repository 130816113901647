import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "ulp-information" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmodulesNavigation = _resolveComponent("SubmodulesNavigation");
    const _component_Address = _resolveComponent("Address");
    const _component_OpeningHours = _resolveComponent("OpeningHours");
    const _component_AccessRestrictions = _resolveComponent("AccessRestrictions");
    const _component_Parking = _resolveComponent("Parking");
    const _component_Infrastructure = _resolveComponent("Infrastructure");
    const _component_WeighingBridge = _resolveComponent("WeighingBridge");
    const _component_CheckIn = _resolveComponent("CheckIn");
    const _component_PPERequirements = _resolveComponent("PPERequirements");
    const _component_UnloadingLoadingPoint = _resolveComponent("UnloadingLoadingPoint");
    const _component_Instruction = _resolveComponent("Instruction");
    const _component_TechnicalRequirements = _resolveComponent("TechnicalRequirements");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoadingOrUnloadingPointActive)
            ? (_openBlock(), _createBlock(_component_SubmodulesNavigation, { key: 0 }))
            : (_ctx.isAddressActive)
                ? (_openBlock(), _createBlock(_component_Address, { key: 1 }))
                : (_ctx.isOpeningHoursActive)
                    ? (_openBlock(), _createBlock(_component_OpeningHours, { key: 2 }))
                    : (_ctx.isAccessRestrictionsActive)
                        ? (_openBlock(), _createBlock(_component_AccessRestrictions, { key: 3 }))
                        : (_ctx.isParkingActive)
                            ? (_openBlock(), _createBlock(_component_Parking, { key: 4 }))
                            : (_ctx.isInfrastructureActive)
                                ? (_openBlock(), _createBlock(_component_Infrastructure, { key: 5 }))
                                : (_ctx.isWeighingBridgeActive)
                                    ? (_openBlock(), _createBlock(_component_WeighingBridge, { key: 6 }))
                                    : (_ctx.isCheckInActive)
                                        ? (_openBlock(), _createBlock(_component_CheckIn, { key: 7 }))
                                        : (_ctx.isPPERequirementsActive)
                                            ? (_openBlock(), _createBlock(_component_PPERequirements, { key: 8 }))
                                            : (_ctx.isUnloadingOrLoadingPointActive)
                                                ? (_openBlock(), _createBlock(_component_UnloadingLoadingPoint, { key: 9 }))
                                                : (_ctx.isInstructionActive)
                                                    ? (_openBlock(), _createBlock(_component_Instruction, { key: 10 }))
                                                    : (_ctx.isTechnicalRequirementsActive)
                                                        ? (_openBlock(), _createBlock(_component_TechnicalRequirements, { key: 11 }))
                                                        : _createCommentVNode("", true)
    ]));
}
