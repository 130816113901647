import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0dbb5ef2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "empty-description"
};
const _hoisted_2 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_UnloadingLoadingAddress = _resolveComponent("UnloadingLoadingAddress");
    const _component_UnloadingLoadingArea = _resolveComponent("UnloadingLoadingArea");
    return (!_ctx.isUlpAvailable || _ctx.allDisabled())
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.unloadingLoadingAddressHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_UnloadingLoadingAddress, {
                    key: 0,
                    address: _ctx.data.address,
                    hasData: _ctx.unloadingLoadingAddressHasData()
                }, null, 8, ["address", "hasData"]))
                : _createCommentVNode("", true),
            (_ctx.unloadingLoadingAreaHasData() || _ctx.areFieldsShown)
                ? (_openBlock(), _createBlock(_component_UnloadingLoadingArea, {
                    key: 1,
                    unloadingLoadingArea: _ctx.data.unloadingLoadingArea,
                    hasData: _ctx.unloadingLoadingAreaHasData()
                }, null, 8, ["unloadingLoadingArea", "hasData"]))
                : _createCommentVNode("", true)
        ]));
}
