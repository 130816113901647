import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d7cda4bc"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "wrapper" };
const _hoisted_2 = { class: "header word-break" };
const _hoisted_3 = {
    key: 0,
    class: "mb-4"
};
const _hoisted_4 = {
    key: 1,
    class: "mb-4"
};
const _hoisted_5 = {
    key: 2,
    class: "mb-4"
};
const _hoisted_6 = {
    key: 3,
    class: "mb-4"
};
const _hoisted_7 = {
    key: 4,
    class: "mb-4"
};
const _hoisted_8 = {
    key: 5,
    class: "mb-4"
};
const _hoisted_9 = { key: 6 };
const _hoisted_10 = { key: 7 };
const _hoisted_11 = {
    key: 0,
    class: "description word-break"
};
const _hoisted_12 = {
    key: 1,
    class: "box"
};
const _hoisted_13 = { class: "basic-info word-break" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    return (!_ctx.isHeaderDisabled)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["display-header", { 'box-active': _ctx.isLoadingOrUnloadingPoint }])
        }, [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    (_ctx.isMenuActive)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("loady2go.top-navigation.menu")), 1))
                        : (_ctx.isLanguageActive)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("loady2go.language.header")), 1))
                            : (_ctx.isOptionsActive)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("loady2go.top-navigation.show-unfilled")), 1))
                                : (_ctx.isFallbackPage)
                                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("loady2go.top-navigation.load-summary")), 1))
                                    : (_ctx.isOrderList)
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t("loady2go.top-navigation.order-list")), 1))
                                        : (_ctx.isSubmoduleActive)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.submoduleTitle), 1))
                                            : (_ctx.isLoadingPointActive && !_ctx.isFallbackPage)
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t("loady2go.top-navigation.loading-information")), 1))
                                                : (_ctx.isUnloadingPointActive && !_ctx.isFallbackPage)
                                                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t("loady2go.top-navigation.unloading-information")), 1))
                                                    : _createCommentVNode("", true)
                ]),
                (_ctx.isLoadingOrUnloadingPoint)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t("loady2go.top-navigation.valid-until")) + " " + _toDisplayString(_ctx.validUntilDate), 1))
                    : _createCommentVNode("", true),
                (_ctx.isLoadingOrUnloadingPoint)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createVNode(_component_Thumbnail, {
                            src: _ctx.companyLogo,
                            icon: "domain"
                        }, null, 8, ["src"]),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.basicInfo), 1)
                    ]))
                    : _createCommentVNode("", true)
            ])
        ], 2))
        : _createCommentVNode("", true);
}
