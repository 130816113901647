import updateApplicationService from "@/app/core/services/update-application.service";
let isReloadingPage = false;
let serviceWorkerContainer;
let serviceWorkerRegistration;
const promptUserToUpdate = () => {
    if (!serviceWorkerContainer.controller) {
        // skip if no previous service-worker installed
        return;
    }
    updateApplicationService.setAppUpdatePending();
};
const waitForServiceWorkerInstallation = () => {
    serviceWorkerRegistration.installing?.addEventListener("statechange", () => {
        if (serviceWorkerRegistration?.waiting) {
            promptUserToUpdate();
        }
    });
};
const reloadPage = () => {
    if (!isReloadingPage) {
        window.location.reload();
        isReloadingPage = true;
    }
};
window.addEventListener("load", async () => {
    serviceWorkerContainer = navigator.serviceWorker;
    if (!serviceWorkerContainer) {
        return;
    }
    serviceWorkerRegistration = (await serviceWorkerContainer.getRegistration());
    if (!serviceWorkerRegistration) {
        return;
    }
    if (serviceWorkerRegistration?.waiting) {
        promptUserToUpdate();
    }
    serviceWorkerRegistration.addEventListener("updatefound", () => {
        waitForServiceWorkerInstallation();
    });
    serviceWorkerContainer.addEventListener("controllerchange", () => {
        reloadPage();
    });
    updateApplicationService.registerUpdateHandler(() => serviceWorkerRegistration?.waiting?.postMessage({ type: "SKIP_WAITING" }));
    updateApplicationService.registerCheckForUpdateHandler(() => serviceWorkerRegistration.update());
});
