import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0e9e06d8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "hose" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = { class: "column-description" };
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = { class: "column-description" };
const _hoisted_6 = { class: "column-header" };
const _hoisted_7 = { class: "column-description" };
const _hoisted_8 = { class: "column-header" };
const _hoisted_9 = { class: "column-description" };
const _hoisted_10 = { class: "column-header" };
const _hoisted_11 = { class: "column-description" };
const _hoisted_12 = { class: "column-header" };
const _hoisted_13 = { class: "column-description" };
const _hoisted_14 = {
    key: 0,
    class: "column-header"
};
const _hoisted_15 = {
    key: 1,
    class: "column-header"
};
const _hoisted_16 = { class: "column-description" };
const _hoisted_17 = { class: "column-header" };
const _hoisted_18 = { class: "column-description" };
const _hoisted_19 = {
    key: 0,
    class: "column-header"
};
const _hoisted_20 = {
    key: 1,
    class: "column-header"
};
const _hoisted_21 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.hose.name'),
            icon: "gesture",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('loady2go.no-data')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hose.hoseToBeProvidedByCarrier !== null
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.provided")), 1),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getTranslation(_ctx.hose.hoseToBeProvidedByCarrier, "yes", "no")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.hose.testingFrequency
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.testing-frequency")), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getValue(_ctx.hose.testingFrequency, "HoseTestingFrequency")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.hose.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.length")), 1),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getValue(_ctx.hose.length, "HoseLength")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hose.diameter > 0
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.diameter.label")), 1),
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.numSingularOrPluralInfo(_ctx.hose.diameter, "sub-modules.hose.form-fields.inch", "sub-modules.hose.form-fields.inches")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.hose.characteristics.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.characteristics.label")), 1),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getValues(_ctx.hose.characteristics, "HoseCharacteristics")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hasLocalisedText(_ctx.hose.additionalInformation)
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.additional-requirements")), 1),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.getLocaleValue(_ctx.hose.additionalInformation)), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hose.hoseLiftingForTopLoading !== null
                }, {
                    default: _withCtx(() => [
                        (_ctx.isLoadingPoint)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.loading-lifting")), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.unloading-lifting")), 1)),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.getTranslation(_ctx.hose.hoseLiftingForTopLoading, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.hose.emptyingTheHoseEnsuredBy
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.emptying")), 1),
                        _createElementVNode("div", {
                            class: _normalizeClass(["column-description", {
                                    isPurgeWithSelected: _ctx.isPurgeWithSelected &&
                                        (_ctx.hasLocalisedText(_ctx.hose.emptyingTheHoseEnsuredByFreeText) || _ctx.$store.state.ui.showIfNoData),
                                }])
                        }, _toDisplayString(_ctx.getValue(_ctx.hose.emptyingTheHoseEnsuredBy, "EmptyingTheHoseEnsuredBy")), 3)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                (_ctx.isPurgeWithSelected)
                    ? (_openBlock(), _createBlock(_component_SubmoduleField, {
                        key: 0,
                        hasData: _ctx.hasLocalisedText(_ctx.hose.emptyingTheHoseEnsuredByFreeText)
                    }, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getLocaleValue(_ctx.hose.emptyingTheHoseEnsuredByFreeText)), 1)
                        ]),
                        _: 1
                    }, 8, ["hasData"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_SubmoduleField, {
                    hasData: _ctx.hose.sievingDeviceForLoading !== null
                }, {
                    default: _withCtx(() => [
                        (_ctx.isLoadingPoint)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.loading-sieving")), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.$t("sub-modules.hose.form-fields.unloading-sieving")), 1)),
                        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.getTranslation(_ctx.hose.sievingDeviceForLoading, "required", "not-required")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
