import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-44e6c5d8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = {
    key: 1,
    class: "label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", {
            class: _normalizeClass(["checkbox", {
                    checked: _ctx.checked,
                }])
        }, [
            (_ctx.checked)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    name: "check"
                }))
                : _createCommentVNode("", true)
        ], 2),
        (_ctx.label)
            ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
        (_ctx.$slots.default)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]))
            : _createCommentVNode("", true)
    ], 64));
}
