import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "equipment-packed" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_4 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.equipment-packed.name'),
            icon: "home_repair_service",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('sub-modules.equipment-packed.no-equipment')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.equipmentPacked.equipments.length
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.equipment-packed.equipment-to-be-provided")), 1),
                        (_ctx.equipmentPacked.equipments.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTranslatedValues(_ctx.equipmentPacked.equipments, 'EquipmentPacked'), (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                        key: item,
                                        class: "chip"
                                    }, _toDisplayString(item), 1));
                                }), 128))
                            ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_4, "-"))
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
