import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-59d1b1ac"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "sealing" };
const _hoisted_2 = { class: "column-header" };
const _hoisted_3 = { class: "column-description" };
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SubModule, {
            title: _ctx.$t('sub-modules.sealing.name'),
            icon: "check_circle",
            hasData: _ctx.hasData,
            noDataMsg: _ctx.$t('loady2go.no-data')
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.sealing.sealingOfVehicleConnections
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("sub-modules.sealing.sealing-of-vehicle-connections")), 1),
                        _createElementVNode("div", {
                            class: _normalizeClass(["column-description", {
                                    isRequiredInSomeCases: _ctx.isRequiredInSomeCases &&
                                        (_ctx.hasLocalisedText(_ctx.sealing.sealingOfVehicleInSomeCases) || _ctx.$store.state.ui.showIfNoData),
                                }])
                        }, _toDisplayString(_ctx.getValue(_ctx.sealing.sealingOfVehicleConnections, "SealingOfVehicleConnections")), 3)
                    ]),
                    _: 1
                }, 8, ["hasData"]),
                (_ctx.isRequiredInSomeCases)
                    ? (_openBlock(), _createBlock(_component_SubmoduleField, {
                        key: 0,
                        hasData: _ctx.hasLocalisedText(_ctx.sealing.sealingOfVehicleInSomeCases)
                    }, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getLocaleValue(_ctx.sealing.sealingOfVehicleInSomeCases)), 1)
                        ]),
                        _: 1
                    }, 8, ["hasData"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_SubmoduleField, {
                    hasData: !!_ctx.sealing.executedBy
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.sealing.sealing-executed-by")), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getValue(_ctx.sealing.executedBy, "SealingIsDoneByWhom")), 1)
                    ]),
                    _: 1
                }, 8, ["hasData"])
            ]),
            _: 1
        }, 8, ["title", "hasData", "noDataMsg"])
    ]));
}
