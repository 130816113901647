import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9d6906ac"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "radio-label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_RadioButton = _resolveComponent("RadioButton");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["sort-button-option", { active: _ctx.isActive }])
    }, [
        _createElementVNode("label", _hoisted_1, [
            _createVNode(_component_RadioButton, {
                class: "radio-input",
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                value: _ctx.value
            }, null, 8, ["modelValue", "value"]),
            _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
        ])
    ], 2));
}
