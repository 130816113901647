import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OpeningHours = _resolveComponent("OpeningHours");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.ulpOpeningHoursTitle,
        icon: "schedule",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.noOpeningHoursInfo,
        showNoDataMsg: ""
    }, {
        default: _withCtx(() => [
            _createVNode(_component_OpeningHours, { openingHours: _ctx.openingHours }, null, 8, ["openingHours"])
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg"]));
}
