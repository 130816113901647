import { onMounted, onUnmounted, reactive } from "vue";
import { useInstance } from "@/app/shared/composables/use-instance.composable";
export const useElementSize = (ref) => {
    const size = reactive({ width: 0, height: 0 });
    const observer = new ResizeObserver((entries) => {
        const el = entries[0].target;
        size.width = el.offsetWidth;
        size.height = el.offsetHeight;
    });
    onMounted(() => {
        const instance = useInstance();
        observer.observe(instance.refs[ref]);
    });
    onUnmounted(() => {
        observer.disconnect();
    });
    return size;
};
